@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&display=swap');

* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

.elan-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center align vertically */
}

.main-image {
  width: 100%;
  height: 100%; /* Stretch image to cover container */
  object-fit: cover; /* Ensure the image covers the container */
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.main-image2 {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.fade-in {
  opacity: 1;
}

.candles-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.candle {
  position: absolute;
  top: -100px; /* Start above the viewport */
  width: 30px;
  height: auto;
  opacity: 0;
  transition: top 3s ease-in-out, opacity 3s ease-in-out;
}

.drop-candle {
  opacity: 1;
  transform: translateY(0);
}

.elan-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  opacity: 0;
  animation: fadeIn 3s forwards;
  z-index: 1; /* Ensure text is above other elements */
}

.committee-name .present{
  font-family: 'Cinzel', Tahoma, Geneva, Verdana, sans-serif;  
}

.event-name{
  font-family: 'Red Rose', Courier, monospace;
  font-weight: bolder;
}

@media (max-width: 600px) {
  .elan-text div:first-child {
    font-size: 1.5rem;
  }
  
  .elan-text div:nth-child(2) {
    font-size: 1rem;
  }
  
  .elan-text div:nth-child(3) {
    font-size: 5rem;
  }
  
  .elan-text div {
    margin: 0;
  }
}

@media (min-width: 601px) {
  .elan-text div:first-child {
    font-size: 2rem;
  }
  
  .elan-text div:nth-child(2) {
    font-size: 1.5rem;
  }
  
  .elan-text div:nth-child(3) {
    font-size: 8rem;
  }
  
  .elan-text div {
    margin: 0;
  }
}

@media (min-width: 770px) and (max-width: 1000px) {
  .main-image {
    display: block; /* Ensure the main image is visible */
  }

  .main-image2 {
    display: none; /* Ensure the alternative image is hidden */
  }

  .candle {
    width: 25px; /* Adjust candle size for medium screens */
  }
}

@media (max-width: 769px) {
  .main-image {
    display: none; /* Hide main image on small screens */
  }

  .main-image2 {
    display: block; /* Show alternative image on small screens */
  }

  .candle {
    width: 20px; /* Smaller candle size for small screens */
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Button Styles */
.register-button {
  position: absolute;
  bottom: 20px; /* Adjusted to move the button up from the bottom */
  left: 47%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #6a0dad; /* Purple background */
  color: #f0e6d6; /* Light cream text color */
  border: 2px solid #d4af37; /* Gold border */
  border-radius: 5px;
  font-size: 1.2rem;
  font-family: 'Garamond', serif; /* Classic font for a Harry Potter feel */
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  opacity: 0; /* Initially hidden */
  display: none; /* Hide the button initially */
}

.register-button.show {
  display: block; /* Show the button */
  animation: fadeInUp 1s forwards; /* Animation to fade in and move up */
}

.register-button:hover {
  background-color: #d4af37; /* Gold background on hover */
  color: #4a4a4a; /* Dark grey text on hover */
  transform: scale(1.05); /* Slightly increase button size on hover */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End position */
  }
}
