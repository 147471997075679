

body {
  margin: 0;
  font-family: 'Poppins', sans-serif; /* Ensure font is applied globally */
}

.experience {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.scrollTarget {
  position: absolute;
  height: 1000vh;
  width: 100px;
  top: 0;
  z-index: 0;
}

.vignette-radial {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
}

.vignette-radial:after {
  pointer-events: none;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: radial-gradient(circle, transparent 60%, black 150%);
}

/* Tube.css */

.navigate-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  opacity: 0.9;
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.navigate-button:hover {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7); /* Enhanced shadow on hover */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 57vw;
  height: 57vh;
  filter: blur 10px;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: hidden; /* Ensure content doesn't overflow */
  font-size: x-large;
  text-align: justify;
}

.popup-content {
  top: 10px;
  position: relative;
  text-align: center;
  width: 100%; /* Ensure the content width fits */
  align-items: center;
}

.popup-close {
  position: absolute;
  /* top: 1px; */
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 36px; /* Increased size for better visibility */
  cursor: pointer;
  transition: color 0.3s; /* Smooth color transition */
  padding: 5px; /* Add padding for better click area */
}

.popup-close:hover {
  color: #ddd; /* Subtle change on hover */
}
.popup-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: #fff;
  background: #6a0dad;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid gold;
  font-family: sans-serif;
  transition: background-color 0s;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.6);
  width: fit-content;
  font-size: 20px; 
}

.popup-link:hover {
  background: #4b0082;
}

.styled-button {
  background-color: #f0a500;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.styled-button:hover {
  background-color: #d18b00;
}

.title-event{
  font-size: xx-large;
}

.event-content{
  text-align: justify;
  top: 20px;
}

@media (max-width: 1200px) {
  .styled-button {
    font-size: 14px;
    padding: 8px 16px; /* Reduce padding for smaller screens */
  }

  .title-event {
    font-size: x-large;
  }

  .event-content {
    font-size: 14px; /* Adjust font size for event-content */
  }
}

@media (max-width: 768px) {
  .styled-button {
    font-size: 12px;
    padding: 6px 12px; /* Further reduce padding for smaller screens */
  }

  .title-event {
    font-size: large;
  }

  .event-content {
    font-size: 12px; /* Adjust font size for event-content */
  }

  .popup-close {
     /* Adjust top position for smaller screens */
    right: 3px; /* Adjust right position for smaller screens */
    font-size: 30px; /* Adjust font size for smaller screens */
  }
  .popup{
    height: fit-content;
  }
}

@media (max-width: 480px) {
  .styled-button {
    font-size: 10px;
    padding: 4px 8px; /* Further reduce padding for smallest screens */
  }

  .title-event {
    font-size: medium;
  }

  .event-content {
    font-size: 10px; /* Adjust font size for event-content */
  }

  .popup-close {
    top: 0px; /* Adjust top position for smallest screens */
    right: 5px; /* Adjust right position for smallest screens */
    font-size: 20px; /* Adjust font size for smallest screens */
  }
}

