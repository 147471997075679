/* .flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.h-screen {
  height: 100vh;
}

.flex-col {
  flex-direction: column;
} */

/* .text-2xl {
  font-size: 2.5rem; 
  line-height: 3rem;
} */

/* .font-bold {
  font-weight: 700;
}

.mb-4 {
  margin-bottom: 1rem;
} */

/* .text-white {
  color: #fff;
}

.text-center {
  text-align: center;
} */

/* .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.relative {
  position: relative;
} */

.circular-chart {
  max-width: 100%;
  height: auto;
}

.percentage {
  fill: #fff;
  font-family: Arial, sans-serif;
  font-size: 24px; /* Adjust the font size for the countdown numbers */
  text-anchor: middle;
}

.label {
  font-size: 1rem; /* Adjust the font size for the labels */
}

/* .text-xs {
  font-size: 16px;
  color: #fff;
  text-align: center;
} */


























