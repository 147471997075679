* {
    box-sizing: border-box; 
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Make sure the entire starting animation fills the viewport */
.mainStartAnimation {
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    font-family: 'Codystar', cursive;
}

.starting-animation-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
}

/* SVG and no-select styles */
svg {
    display: inline-block;
}

.no-select {
    cursor: default;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

/* Film container covers the full screen */
#film-container {
    width: 100%;
    height: 100%;
    background: url('//res.cloudinary.com/thdoan/image/upload/v1509581279/countdown/film_grain-small.gif') no-repeat center center;
    background-size: cover;
    opacity: 1;
    transition: opacity 2s;
}

#film-countdown,
#message-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#message-container {
    z-index: 1;
}

/* Responsive text styling for the message container */
#message-container text {
    font: normal clamp(16px, 2vw, 28px) Calibri, sans-serif;
    fill: #111;
    opacity: 0;
}

body.message #film-container {
    opacity: 0;
}

body.message #message-container text {
    opacity: 1;
    transition: opacity 2s;
}

/* Countdown numbers - cover full width and adjust based on viewport */
#numbers text {
    fill: black;
    font: bold clamp(80px, 10vw, 120px) Arial, sans-serif;
    letter-spacing: clamp(-6px, -1vw, -10px);
    opacity: 0;
}

.circle01 {
    fill: none;
    stroke: #111;
    stroke-width: 1.5px;
}

.circle02 {
    fill: none;
    stroke: rgba(0, 0, 0, 0.3);
    stroke-dasharray: 100 3100;
    stroke-width: 989;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.line01,
.line02 {
    stroke: #deedee;
}

.rectangle01 {
    fill: gray;
}

/*** Animations ***/
.animated-flicker {
    height: 100%;
    animation: flicker 2s 1;
    opacity: 0.2;
}

.animated-rotate {
    animation: rotate 0.666s 3;
}

#animated-text1 {
    animation: countdown 0.666s 1;
    animation-delay: 0s;
}

#animated-text2 {
    animation: countdown 0.666s 1;
    animation-delay: 0.666s;
}

#animated-text3 {
    animation: countdown 0.666s 1;
    animation-delay: 1.332s;
}

/* Countdown container */
.countdown-container {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.countdown-container.fade-out {
    opacity: 0;
}

/* Spotlight container styles (also full screen) */
.spotlight-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.spotlight-container.fade-in {
    opacity: 1;
}

@keyframes countdown {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes flicker {
    0% {
        opacity: 0.45;
    }
    15% {
        opacity: 0.7;
    }
    30% {
        opacity: 0.85;
    }
    45% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    75% {
        opacity: 0.8;
    }
    85% {
        opacity: 0.4;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes rotate {
    100% {
        stroke-dasharray: 3100 100;
    }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
    #message-container text {
        font: normal clamp(14px, 2.5vw, 24px) Calibri, sans-serif;
    }
    #numbers text {
        font: bold clamp(70px, 10vw, 110px) Arial, sans-serif;
        letter-spacing: clamp(-5px, -0.8vw, -9px);
    }
}

@media (max-width: 768px) {
    #message-container text {
        font: normal clamp(12px, 3vw, 20px) Calibri, sans-serif;
    }
    #numbers text {
        font: bold clamp(60px, 12vw, 100px) Arial, sans-serif;
        letter-spacing: clamp(-4px, -1.2vw, -8px);
    }
}

@media (max-width: 480px) {
    #message-container text {
        font: normal clamp(10px, 4vw, 18px) Calibri, sans-serif;
    }
    #numbers text {
        font: bold clamp(50px, 15vw, 80px) Arial, sans-serif;
        letter-spacing: clamp(-3px, -1.5vw, -7px);
    }
}