/* src/styles/GoldenSnitch.css */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
:root{
  --snitch-border: #3C2E2D;
  --light-yellow:#d3a625;
  --snitch-body: #eeba30;
  --bg-light:#740001;
  --bg-dark:#390809;
  --m-cube: 200px;
}

body{
  background-color: black;
}

#registerButton {
  position: absolute;
  bottom: 30%; /* Position 1/4th above the bottom of the screen */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  background-color: #520d0d; /* Dark purple color */
  color: #fff;
  border: 2px solid gold;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "Harry P", sans-serif; /* Use a Harry Potter themed font */
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.6); /* Glow effect */
  transition: background-color 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  display: block; /* Ensure it is a block element */
  z-index: 1000; /* Ensure it appears on top */
}

#registerButton.show {
  opacity: 1;
  visibility: visible; /* Ensure it is visible */
  transition: opacity 1s, visibility 1s; /* Smooth transition */
}

#registerButton:hover {
  background-color: #4b0082; /* Darker shade on hover */
  transform: translateX(-50%) scale(1.1);
}
.test {
  transform: scale(0.15);
}

#snitch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  animation: spiralTrail 10s linear infinite;
  z-index: 10;
}

#bg{
  width:100vw;
  height: 100vh;
/*   background: var(--bg-light); */
  box-sizing:border-box;
  padding:10vh 15vw;
}


#snitch>#body{
  width:200px;
  height:200px;
  background:var(--snitch-body);
  border-radius: 100%;
  position:absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border: 10px solid var(--snitch-border);
  overflow: hidden;
  z-index:3;
}
#e-line{
  width:200px;
  height:200px;
  border-radius: 100%;
  position:absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - 165px);
  left: 50%;
  border: 10px solid var(--snitch-border);
  overflow: hidden;
}
#e-circle{
  width:100px;
  height:100px;
  border-radius: 100%;
  position:absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - 70px);
  left: calc(50% - 65px);
  border: 10px solid var(--snitch-border);
  overflow: hidden;
  -webkit-box-reflect: right 5px;
}
#b-circle{
  width:100px;
  height:100px;
  border-radius: 0 60px 100px 0px;
  position:absolute;
  transform: translate(-50%, -50%);
  top: calc(50% + 60px);
  left: calc(50% - 60px);
  border: 10px solid var(--snitch-border);
  overflow: hidden;
  -webkit-box-reflect: right 0px;
}

#b-circle>div{
  width:100px;
  height:100px;
  border-radius: 0 60px 100px 0px;
  position:absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - 30px - (var(--i) * 20px));
  left: calc(50% + 10px);
  border: 10px solid var(--snitch-border);
  overflow: hidden;
}

#eye{
  width:20px;
  height:20px;
  background:#eeba30;
  border-radius: 100%;
  position:absolute;
  transform: translate(-50%, -50%);
  top: calc(50% - 80px);
  left: calc(50% - 70px);
  border: 10px solid var(--snitch-border);
  -webkit-box-reflect: right 100px;
  animation: blink2 2.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 2s infinite !important;
  z-index:4;
}
#wing, #wing2{
  width:380px;
  height:130px;
  background:#FAF3E5;
  border-radius: 10px 100px 0px 200px;
  position:absolute;
  transform: translate(-50%, -50%) rotate(25deg);
  top: calc(50% - 40px);
  left: calc(50% - 210px);
  border: 10px solid var(--snitch-border);
  z-index:1;
  transform-origin: right;
  overflow:hidden;
}
#wing{
  animation: wing-animation .15s ease infinite;
}
#wing2{
  transform: translate(-50%, -50%) rotate(-25deg) scaleX(-1);
  left: calc(50% - 190px);
  animation: wing2-animation .15s ease infinite;

}
#wing>div, #wing2>div{
  width:550px;
  height:300px;
  border-radius: 100%;
  position:absolute;
  transform: translate(-50%, -50%) rotate(-120deg);
  top: calc(50% - 20px);
  left: calc(50% + 20px + (var(--i) * 20px));
  border: 10px solid rgba(60, 46, 45, 0.28);
}

@keyframes shadow-animation{
  0%, 100%{
    transform: translateY(0px) scale(1);
  }50%{
    transform: translateY(-50%) scale(.9);
  }
}
@keyframes fly-animation{
  0%, 100%{
    transform: translate(0%, -40vh);
  }50%{
    transform: translate(0%, -50vh);
  }
}

@keyframes blink2 {
  0%, 8% {
    height: 20px;
  }
  10%, 12% {
    height: 5px;
  }
  14%, 100% {
    height: 20px;
  }
}
@keyframes wing-animation{
  0%, 100%{
    transform: translate(-50%, -50%) rotate(25deg);
  }
  50%{
    transform: translate(-50%, -50%) rotate(10deg);
  }
}
@keyframes wing2-animation{
  0%, 100%{
    transform: translate(-50%, -50%) rotate(-25deg) scaleX(-1);
  }
  50%{
    transform: translate(-50%, -50%) rotate(-10deg) scaleX(-1);
  }
}


/* Keyframes for smoother spiral path */
@keyframes spiralTrail {
  0% {
    transform: translate(-50%, -50%) translate(-10vw, -30vh) rotate(0deg);
  }
  5% {
    transform: translate(-50%, -50%) translate(-10vw, -20vh) rotate(30deg);
  }
  10% {
    transform: translate(-50%, -50%) translate(0vw, -40vh) rotate(60deg);
  }
  15% {
    transform: translate(-50%, -50%) translate(10vw, -30vh) rotate(90deg);
  }
  20% {
    transform: translate(-50%, -50%) translate(20vw, -20vh) rotate(120deg);
  }
  25% {
    transform: translate(-50%, -50%) translate(30vw, -10) rotate(150deg);
  }
  30% {
    transform: translate(-50%, -50%) translate(20vw, 0vh) rotate(180deg);
  }
  35% {
    transform: translate(-50%, -50%) translate(10vw, 10vh) rotate(210deg);
  }
  40% {
    transform: translate(-50%, -50%) translate(0vw, 20vh) rotate(240deg);
  }
  45% {
    transform: translate(-50%, -50%) translate(-10vw, 30vh) rotate(270deg);
  }
  50% {
    transform: translate(-50%, -50%) translate(-20vw, 20vh) rotate(300deg);
  }
  55% {
    transform: translate(-50%, -50%) translate(-30vw, 10vh) rotate(330deg);
  }
  60% {
    transform: translate(-50%, -50%) translate(-40vw, 0vh) rotate(360deg);
  }
  65% {
    transform: translate(-50%, -50%) translate(-50vw, -10vh) rotate(390deg);
  }
  70% {
    transform: translate(-50%, -50%) translate(-40vw, -20vh) rotate(420deg);
  }
  75% {
    transform: translate(-50%, -50%) translate(-30vw, -30vh) rotate(450deg);
  }
  80% {
    transform: translate(-50%, -50%) translate(-20vw, -40vh) rotate(480deg);
  }
  85% {
    transform: translate(-50%, -50%) translate(-10vw, -50vh) rotate(510deg);
  }
  90% {
    transform: translate(-50%, -50%) translate(0vw, -40vh) rotate(540deg);
  }
  95% {
    transform: translate(-50%, -50%) translate(10vw, -30vh) rotate(570deg);
  }
  100% {
    transform: translate(-50%, -50%) translate(20vw, -20vh) rotate(600deg);
  }
}

@media (max-width: 600px) {
  #registerButton {
    bottom: 40%; /* Adjust bottom position for small screens */
    padding: 8px 16px; /* Adjust padding for small screens */
    font-size: 16px; /* Adjust font size for small screens */
  }
}