* {
    box-sizing: border-box;
    /*  */
}
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.mainSpotlight {
    width: 100%;
    height: 100vh;
    background: rgb(9, 21, 40);
    overflow: hidden;
    font-family: 'Codystar', cursive;
}
.wrapper {
    width: 90vw;
    height: 100vh;
    max-width: 150vh;
    margin: 0 auto;
    position: relative;
}
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(
        #000000,
        rgba(25, 53, 93, 1) 90%,
        rgba(25, 53, 93, 1)
    );
}
.stage_floor {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    height: 150%;
    background-image: radial-gradient(
        rgba(54, 90, 143, 0.9),
        rgba(54, 90, 143, 0)
    );
    transform: scale(1, 0.3);
}
.stage_highlight {
    position: absolute;
    top: 0;
    left: 25vh;
    width: 100vh;
    height: 100%;
    overflow: hidden;
    background-image: radial-gradient(
        ellipse closest-side at 50% 82%,
        rgb(84, 120, 173),
        rgba(84, 120, 173, 0) 100%
    );
    animation: move_highlight 5s linear infinite;
}
.spotlight_swivel {
    animation: rotate_spotlight 5s linear infinite;
}
.lamp {
    position: absolute;
    width: 40vh;
    height: 400vh;
    background-image: radial-gradient(
        ellipse,
        rgba(82, 116, 168, 0.5),
        rgba(82, 116, 168, 0.2) 25%,
        rgba(82, 116, 168, 0) 50%
    );
    top: -220vh;
    left: 55vh;
    transform: perspective(23vh) rotateX(30deg);
}
.spotlight {
    opacity: 0.5;
    transform: scale(1.5, 3);
    position: absolute;
    top: -20vh;
    left: calc(50% - 75vh);
}
.spotlight::after {
    content: '';
    position: absolute;
    top: -50vh;
    background-image: radial-gradient(
        ellipse closest-corner at 75% 75%,
        rgba(55, 89, 138, 0.2),
        rgba(55, 89, 138, 0)
    );
    border-radius: 100%;
    width: 100vh;
    height: 100vh;
    clip: rect(50vh, 100vh, 100vh, 50vh);
    transform: rotate(45deg);
}

/* Ensure text appears above the other elements */
.spotlight-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.8);
}
/* .spotlight-image {
    width: 200%;
    max-width: 5000px;
    margin-top: -50px;
    border-radius: 10px;
} */
.register-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    font-size: clamp(1rem, 2vw, 1.2rem);
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #d60000, #ffcc00);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 0 15px rgba(255, 204, 0, 0.6);
    transition: all 0.3s ease-in-out;
}
.register-button:hover {
    background: linear-gradient(45deg, #ffcc00, #d60000);
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 204, 0, 0.9);
}

@keyframes rotate_spotlight {
    0% {
        transform: rotate(0deg) scaleY(1) translateY(0);
    }
    25% {
        transform: rotate(-15deg) scaleY(1.1) translateY(-3vh);
    }
    50% {
        transform: rotate(0deg) scaleY(1) translateY(0);
    }
    75% {
        transform: rotate(15deg) scaleY(1.1) translateY(-3vh);
    }
}
@keyframes move_highlight {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(25vh);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-25vh);
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .wrapper {
        width: 95vw;
        height: 95vh;
    }
    .stage_highlight {
        left: 20vw;
        width: 60vw;
    }
    .lamp {
        width: 35vw;
        height: 350vh;
        left: 50vw;
        transform: perspective(20vw) rotateX(30deg);
    }
    .spotlight {
        left: calc(50% - 50vw);
    }
    .spotlight-text {
        font-size: clamp(1.5rem, 3vw, 2rem);
    }
}
@media (max-width: 768px) {
    .wrapper {
        width: 100vw;
        height: 90vh;
    }
    .stage_highlight {
        left: 10vw;
        width: 80vw;
    }
    .lamp {
        width: 30vw;
        height: 300vh;
        left: 50vw;
    }
    .spotlight {
        left: calc(50% - 40vw);
    }
    .spotlight-text {
        font-size: clamp(1.2rem, 4vw, 1.8rem);
    }
    /* .spotlight-image {
        width: 100%;
    } */
}
@media (max-width: 480px) {
    .wrapper {
        width: 100vw;
        height: 80vh;
    }
    .stage_highlight {
        left: 5vw;
        width: 90vw;
    }
    .lamp {
        width: 25vw;
        height: 250vh;
        left: 50vw;
    }
    .spotlight {
        left: calc(50% - 35vw);
    }
    .spotlight-text {
        font-size: clamp(1rem, 5vw, 1.5rem);
    }
    /* .spotlight-image {
        width: 100%;
    } */
    .register-button {
        font-size: clamp(0.9rem, 3vw, 1rem);
        padding: 8px 16px;
    }
}
