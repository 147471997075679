/* cursorbody, .custom-cursor-element { 
    cursor: url('./assets/wand.png'), auto; 
} */
/* Global Styles */
.main {
    /* background-color: transparent; */
    margin: 0;
    height: 100%;
    overflow: hidden; /* Hide overflow for both x and y */
}

/* Wrapper to hide overflow */
.wrapper {
    width: 100%;
    overflow: hidden; /* Hide horizontal overflow */
}

/* Slide container */
.container {
    display: flex; /* Ensure slides are aligned in a row */
    overflow-x: auto; /* Allow horizontal scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

.container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.container .slide {
    background: #454545;
    border: 1px solid #000;
    box-sizing: border-box;
    position: relative;
    margin-right: 0px; /* Remove gap between slides */
    flex-shrink: 0;
    width: 200px; /* Ensure width matches the slideWidth in JS */
    height: 200px; /* Ensure height matches the slideWidth in JS */
}

/* Image inside the slide */
.container .slide img {
    width: 100%; /* Fill entire width of the slide */
    height: 100%; /* Fill entire height of the slide */
    object-fit: contain; /* Ensure the image is fully visible */
    padding: 0; /* Remove padding to ensure no background */
}

/* Notches container styles */
.notches {
    position: absolute;
    display: flex;
    width: 100%;
}

.notchesSub {
    background: #fff;
    flex: 1;
    border-left: 10px solid #000;
    box-sizing: border-box;
    box-shadow: inset 5px 1px #444;
}

/* Additional styles for notches positioning */
.notches {
    left: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .container .slide {
        width: 140px;
        height: 140px;
    }

    .container .slide img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 480px) {
    .container .slide {
        width: 100px;
        height: 100px;
    }

    .container .slide img {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 640px) {
    #regContainer {
        background-image: url('./assets/osciMobile.jpg');
        background-size: cover;
        background-position: center;
    }
}

@media (min-width: 643px) {
    #regContainer {
        background-image: url('./assets/osciDesktop.jpg');
        background-size: cover;
        background-position: center;
    }
}

.main {
    overflow: hidden;
}

.splFont {
    font-family: 'Darumadrop One', Courier, monospace;
}

.form-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.form-scrollbar::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.form-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    border: 3px solid rgba(255, 255, 255, 0.1);
}

.slider-wrapper {
    overflow: hidden;
    position: relative;
    height: 30vh; /* Or your preferred height */
  }
  
  .slider-container {
    display: flex;
    width: max-content;
    animation: scroll 20s linear infinite;
  }
  
  .slide {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    /* Additional styling as needed */
  }
  
  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      /* Translate by half the container's width (the width of one set of slides) */
      transform: translateX(-50%);
    }
  }
  