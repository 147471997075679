.mainIplLoader {
    box-sizing: border-box;
}

.mainIplLoader {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
}

img {
    display: block;
}

p {
    margin-top: 2.5rem;
    letter-spacing: 0.1rem;
    color: rgb(127, 69, 69);
}

main {
    /* height: 90vh; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#loader {
    position: relative;
    width: 150px;
    height: 80px;
    background-color: transparent;
}

#ball,
#bat {
    position: absolute;
}

#ball {
    width: 30px;
    height: 30px;
    left: 0.5rem;
    top: 2.7rem;
    z-index: 2;
    animation: bounceBall 1s infinite;
    animation-delay: 1s;
}

#bat {
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transform: rotateX(-40deg);
    animation: moveBat 1s infinite;
}

/* bounce animation snippet from animate.css library with some changes */
@keyframes bounceBall {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -60px, 0);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(
            0.755,
            0.05,
            0.855,
            0.06
        );
        transform: translate3d(0, -30px, 0);
    }

    90% {
        transform: translate3d(0, -8px, 0);
    }
}

@keyframes moveBat {
    0% {
        transform: rotateX(0deg);
    }

    33% {
        transform: rotateX(-25deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}
