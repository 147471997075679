@-webkit-keyframes animate-svg-stroke-1 {
   0% {
      stroke-dashoffset: 1540.9847412109375px;
      stroke-dasharray: 1540.9847412109375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1540.9847412109375px;
   }
}

@keyframes animate-svg-stroke-1 {
   0% {
      stroke-dashoffset: 1540.9847412109375px;
      stroke-dasharray: 1540.9847412109375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1540.9847412109375px;
   }
}

.svg-elem-1 {
   -webkit-animation: animate-svg-stroke-1 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
   animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
      both;
}

@-webkit-keyframes animate-svg-stroke-2 {
   0% {
      stroke-dashoffset: 86.03997802734375px;
      stroke-dasharray: 86.03997802734375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 86.03997802734375px;
   }
}

@keyframes animate-svg-stroke-2 {
   0% {
      stroke-dashoffset: 86.03997802734375px;
      stroke-dasharray: 86.03997802734375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 86.03997802734375px;
   }
}

.svg-elem-2 {
   -webkit-animation: animate-svg-stroke-2 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both;
   animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s
      both;
}

@-webkit-keyframes animate-svg-stroke-3 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

@keyframes animate-svg-stroke-3 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

.svg-elem-3 {
   -webkit-animation: animate-svg-stroke-3 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s both;
   animation: animate-svg-stroke-3 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s
      both;
}

@-webkit-keyframes animate-svg-stroke-4 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

@keyframes animate-svg-stroke-4 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

.svg-elem-4 {
   -webkit-animation: animate-svg-stroke-4 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s both;
   animation: animate-svg-stroke-4 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s
      both;
}

@-webkit-keyframes animate-svg-stroke-5 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

@keyframes animate-svg-stroke-5 {
   0% {
      stroke-dashoffset: 181.01998901367188px;
      stroke-dasharray: 181.01998901367188px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 181.01998901367188px;
   }
}

.svg-elem-5 {
   -webkit-animation: animate-svg-stroke-5 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s both;
   animation: animate-svg-stroke-5 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s
      both;
}

@-webkit-keyframes animate-svg-stroke-6 {
   0% {
      stroke-dashoffset: 196.99998474121094px;
      stroke-dasharray: 196.99998474121094px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 196.99998474121094px;
   }
}

@keyframes animate-svg-stroke-6 {
   0% {
      stroke-dashoffset: 196.99998474121094px;
      stroke-dasharray: 196.99998474121094px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 196.99998474121094px;
   }
}

.svg-elem-6 {
   -webkit-animation: animate-svg-stroke-6 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
   animation: animate-svg-stroke-6 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s
      both;
}

@-webkit-keyframes animate-svg-stroke-7 {
   0% {
      stroke-dashoffset: 152.61997985839844px;
      stroke-dasharray: 152.61997985839844px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 152.61997985839844px;
   }
}

@keyframes animate-svg-stroke-7 {
   0% {
      stroke-dashoffset: 152.61997985839844px;
      stroke-dasharray: 152.61997985839844px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 152.61997985839844px;
   }
}

.svg-elem-7 {
   -webkit-animation: animate-svg-stroke-7 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s both;
   animation: animate-svg-stroke-7 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s
      both;
}

@-webkit-keyframes animate-svg-stroke-8 {
   0% {
      stroke-dashoffset: 160.78997802734375px;
      stroke-dasharray: 160.78997802734375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 160.78997802734375px;
   }
}

@keyframes animate-svg-stroke-8 {
   0% {
      stroke-dashoffset: 160.78997802734375px;
      stroke-dasharray: 160.78997802734375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 160.78997802734375px;
   }
}

.svg-elem-8 {
   -webkit-animation: animate-svg-stroke-8 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s both;
   animation: animate-svg-stroke-8 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s
      both;
}

@-webkit-keyframes animate-svg-stroke-9 {
   0% {
      stroke-dashoffset: 141.75px;
      stroke-dasharray: 141.75px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 141.75px;
   }
}

@keyframes animate-svg-stroke-9 {
   0% {
      stroke-dashoffset: 141.75px;
      stroke-dasharray: 141.75px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 141.75px;
   }
}

.svg-elem-9 {
   -webkit-animation: animate-svg-stroke-9 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s both;
   animation: animate-svg-stroke-9 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s
      both;
}

@-webkit-keyframes animate-svg-stroke-10 {
   0% {
      stroke-dashoffset: 69.05999755859375px;
      stroke-dasharray: 69.05999755859375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05999755859375px;
   }
}

@keyframes animate-svg-stroke-10 {
   0% {
      stroke-dashoffset: 69.05999755859375px;
      stroke-dasharray: 69.05999755859375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05999755859375px;
   }
}

.svg-elem-10 {
   -webkit-animation: animate-svg-stroke-10 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s both;
   animation: animate-svg-stroke-10 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s
      both;
}

@-webkit-keyframes animate-svg-stroke-11 {
   0% {
      stroke-dashoffset: 69.05609130859375px;
      stroke-dasharray: 69.05609130859375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05609130859375px;
   }
}

@keyframes animate-svg-stroke-11 {
   0% {
      stroke-dashoffset: 69.05609130859375px;
      stroke-dasharray: 69.05609130859375px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05609130859375px;
   }
}

.svg-elem-11 {
   -webkit-animation: animate-svg-stroke-11 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s both;
   animation: animate-svg-stroke-11 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s
      both;
}

@-webkit-keyframes animate-svg-stroke-12 {
   0% {
      stroke-dashoffset: 69.06363677978516px;
      stroke-dasharray: 69.06363677978516px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.06363677978516px;
   }
}

@keyframes animate-svg-stroke-12 {
   0% {
      stroke-dashoffset: 69.06363677978516px;
      stroke-dasharray: 69.06363677978516px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.06363677978516px;
   }
}

.svg-elem-12 {
   -webkit-animation: animate-svg-stroke-12 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s both;
   animation: animate-svg-stroke-12 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.3199999999999998s both;
}

@-webkit-keyframes animate-svg-stroke-13 {
   0% {
      stroke-dashoffset: 69.05480194091797px;
      stroke-dasharray: 69.05480194091797px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05480194091797px;
   }
}

@keyframes animate-svg-stroke-13 {
   0% {
      stroke-dashoffset: 69.05480194091797px;
      stroke-dasharray: 69.05480194091797px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.05480194091797px;
   }
}

.svg-elem-13 {
   -webkit-animation: animate-svg-stroke-13 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s both;
   animation: animate-svg-stroke-13 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s
      both;
}

@-webkit-keyframes animate-svg-stroke-14 {
   0% {
      stroke-dashoffset: 69.06439971923828px;
      stroke-dasharray: 69.06439971923828px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.06439971923828px;
   }
}

@keyframes animate-svg-stroke-14 {
   0% {
      stroke-dashoffset: 69.06439971923828px;
      stroke-dasharray: 69.06439971923828px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 69.06439971923828px;
   }
}

.svg-elem-14 {
   -webkit-animation: animate-svg-stroke-14 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s both;
   animation: animate-svg-stroke-14 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s
      both;
}

@-webkit-keyframes animate-svg-stroke-15 {
   0% {
      stroke-dashoffset: 56.32000732421875px;
      stroke-dasharray: 56.32000732421875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.32000732421875px;
   }
}

@keyframes animate-svg-stroke-15 {
   0% {
      stroke-dashoffset: 56.32000732421875px;
      stroke-dasharray: 56.32000732421875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.32000732421875px;
   }
}

.svg-elem-15 {
   -webkit-animation: animate-svg-stroke-15 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s both;
   animation: animate-svg-stroke-15 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s
      both;
}

@-webkit-keyframes animate-svg-stroke-16 {
   0% {
      stroke-dashoffset: 140.52001953125px;
      stroke-dasharray: 140.52001953125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 140.52001953125px;
   }
}

@keyframes animate-svg-stroke-16 {
   0% {
      stroke-dashoffset: 140.52001953125px;
      stroke-dasharray: 140.52001953125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 140.52001953125px;
   }
}

.svg-elem-16 {
   -webkit-animation: animate-svg-stroke-16 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s both;
   animation: animate-svg-stroke-16 1s cubic-bezier(0.47, 0, 0.745, 0.715)
      1.7999999999999998s both;
}

@-webkit-keyframes animate-svg-stroke-17 {
   0% {
      stroke-dashoffset: 37.92999267578125px;
      stroke-dasharray: 37.92999267578125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.92999267578125px;
   }
}

@keyframes animate-svg-stroke-17 {
   0% {
      stroke-dashoffset: 37.92999267578125px;
      stroke-dasharray: 37.92999267578125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.92999267578125px;
   }
}

.svg-elem-17 {
   -webkit-animation: animate-svg-stroke-17 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s both;
   animation: animate-svg-stroke-17 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s
      both;
}

@-webkit-keyframes animate-svg-stroke-18 {
   0% {
      stroke-dashoffset: 140.52001953125px;
      stroke-dasharray: 140.52001953125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 140.52001953125px;
   }
}

@keyframes animate-svg-stroke-18 {
   0% {
      stroke-dashoffset: 140.52001953125px;
      stroke-dasharray: 140.52001953125px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 140.52001953125px;
   }
}

.svg-elem-18 {
   -webkit-animation: animate-svg-stroke-18 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s both;
   animation: animate-svg-stroke-18 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s
      both;
}

@-webkit-keyframes animate-svg-stroke-19 {
   0% {
      stroke-dashoffset: 37.91998291015625px;
      stroke-dasharray: 37.91998291015625px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.91998291015625px;
   }
}

@keyframes animate-svg-stroke-19 {
   0% {
      stroke-dashoffset: 37.91998291015625px;
      stroke-dasharray: 37.91998291015625px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 37.91998291015625px;
   }
}

.svg-elem-19 {
   -webkit-animation: animate-svg-stroke-19 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s both;
   animation: animate-svg-stroke-19 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s
      both;
}

@-webkit-keyframes animate-svg-stroke-20 {
   0% {
      stroke-dashoffset: 58.790008544921875px;
      stroke-dasharray: 58.790008544921875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 58.790008544921875px;
   }
}

@keyframes animate-svg-stroke-20 {
   0% {
      stroke-dashoffset: 58.790008544921875px;
      stroke-dasharray: 58.790008544921875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 58.790008544921875px;
   }
}

.svg-elem-20 {
   -webkit-animation: animate-svg-stroke-20 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s both;
   animation: animate-svg-stroke-20 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s
      both;
}

@-webkit-keyframes animate-svg-stroke-21 {
   0% {
      stroke-dashoffset: 56.32000732421875px;
      stroke-dasharray: 56.32000732421875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.32000732421875px;
   }
}

@keyframes animate-svg-stroke-21 {
   0% {
      stroke-dashoffset: 56.32000732421875px;
      stroke-dasharray: 56.32000732421875px;
   }

   100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 56.32000732421875px;
   }
}

.svg-elem-21 {
   -webkit-animation: animate-svg-stroke-21 1s
      cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s both;
   animation: animate-svg-stroke-21 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s
      both;
}

.logoSplash {
   width: 200px;
   height: 200px;
}
.splashbg {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
}

@media (max-width: 800px) {
   .logoSplash {
      margin-top: -100px;
   }
}
